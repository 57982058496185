.dropdownSelectInput {
  width: 18rem;
  /* padding: 0.5rem 0 !important; */
}

.displayLabelSelectDropdown {
  display: block;
  font-size: 0.8rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
}

.menuListItem {
  font-size: 0.9rem !important;
}
