.reassignAndTerminate_container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem;
}

.reassignAndTerminate_tableContainer::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
  height: 12px;
}
.reassignAndTerminate_tableContainer::-webkit-scrollbar-track {
  border-radius: 5px;
}
.reassignAndTerminate_tableContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  /* background-color: #848482; */
}

.reassignAndTerminateSearchUser_textFieldWithButton,
.reassignAndTerminateRASearchApprover_textFieldWithButton {
  display: flex;
}
.reassignAndTerminateSearchUser_textField,
.reassignAndTerminateRASearchApprover_textField {
  width: 25rem;
  font-style: italic;
  color: #9a9898;
  text-transform: capitalize;
  background: #fff;
}

.reassignAndTerminateRASearchApprover_textField {
  width: 28rem;
}

.reassignAndTerminateSearchUser_crossIcon,
.reassignAndTerminateRASearchApprover_crossIcon {
  font-size: 2rem !important;
}

.reassignAndTerminateSearchUser_searchButton,
.reassignAndTerminateRASearchApprover_searchButton {
  background-color: #ffc5a0cc !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
}

.reassignAndTerminateSearchUser_userList,
.reassignAndTerminateRASearchApprover_userList {
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.reassignAndTerminate_searchFilterDownload {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 1rem;
}

.btn {
  background-color: #ffc5a0cc !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
  padding: 0.4rem !important;
}

.btn--filter {
  margin-right: 0.5rem !important;
}

.btn--download {
  text-transform: lowercase !important;
}

.filterIcon,
.donwloadIcon,
.searchIcon {
  font-size: 2rem !important;
}

.reassignAndTerminate_tableContainer {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
}

.reassignAndTerminate_modalCard {
  width: 100%;
  height: 100%;
}

.reassignAndTerminate_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 700px;
  min-height: 200px !important;
  border-radius: 10px;
  background-color: white;
}

.reassignAndTerminate_modalCardHeader,
.reassignAndTerminateRA_modalCardHeader {
  width: 100%;
  height: 2.5rem;
  background: #ffeee5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.reassignAndTerminate_Type {
  padding: 1rem 1rem 0 1rem;
}

.reassignAndTerminate_TypeHeading {
  font-size: 1rem;
  font-weight: 700;
}

.reassignAndTerminate_TypeChip {
  margin: 0.5rem 0.5rem 0.5rem 0 !important;
}

.reassignAndTerminate_TypeChipSelected {
  background-color: #ffc5a0cc !important;
}

.reassignAndTerminate_TypeChip:hover {
  cursor: pointer;
}

.reassignAndTerminate_modalCardFooter {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

.reassignAndTerminate_terminateModalContainer {
  min-width: 600px;
  min-height: 300px;
}

.reassignAndTerminate_terminateModalContainer > div {
  margin-bottom: 10px;
}

.reassignAndTerminate_terminate_topBorder {
  border-radius: 10px 10px 0px 0px;
  background-color: #d52b1e;
  height: 12px;
}
.terminate_icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.reassignAndTerminate_iconFail {
  border-radius: 50%;
  border: 1px dotted #d52b1e;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reassignAndTerminate_terminateInstrWithTextBox {
  text-align: center;
}

.reassignAndTerminate_terminateInstruction,
.reassignAndTerminate_terminateLabel {
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
}
.reassignAndTerminate_terminateInstruction {
  margin-bottom: 0.5rem;
}

.reassignAndTerminate_terminateLabel {
  margin-bottom: 0.2rem;
}

.reassignAndTerminate_terminatelabelWithTextBox {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 70%;
  margin: 0 auto;
}

.terminate_textBox {
  border-radius: 8px;
  resize: none !important;
}

.reassignAndTerminate_terminateActionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
}

.reassignAndTerminateRA_ModalCard {
  min-width: 600px;
  min-height: 300px;
}

.reassignAndTerminateRA_noTasks {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.reassignAndTerminateRASearchApprover_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 0 15px 0 !important;
}

.reassignAndTerminateRASearchApprover_searchText {
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 1rem;
}

.reassignAndTerminateRATasks_name {
  font-size: 0.9rem;
  font-weight: 700;
  /* flex-direction: column; */
}

.reassignAndTerminateRASearchApprover_mainHead {
  font-size: 1.2rem;
  font-weight: 700;
  padding-left: 1rem;
}

.reassignAndTerminateRATasks_detail {
  font-size: 0.9rem;
  font-weight: 400;
  /* word-break: break-all; */
  word-wrap: break-word;
}

.reassignAndTerminateRATasks_wrapper {
  max-width: 99%;
  max-height: 360px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.reassignAndTerminateRATasks_taskHead {
  font-size: 1rem;
  font-weight: 700;
}

.reassignAndTerminateRATasks_reassignAction {
  display: flex;
  justify-content: flex-end;
}

.reassignAndTerminateRASearchApprover_approverActions {
  align-self: flex-end;
  margin: 20px 20px 0 0;
}

.reassignAndTerminate_msgModalContainer > div {
  margin-bottom: 10px;
}

.reassignAndTerminate_topBorderError {
  border-radius: 10px 10px 0px 0px;
  background-color: #d52b1e;
  height: 12px;
}

.reassignAndTerminate_topBorderSuccess {
  border-radius: 10px 10px 0px 0px;
  background-color: #00ae43;
  height: 12px;
}
.reassignAndTerminate_icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.reassignAndTerminate_iconError {
  border-radius: 50%;
  border: 1px dotted #d52b1e;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reassignAndTerminate_iconSuccess {
  border-radius: 50%;
  border: 2px dotted #00ae43;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.reassignAndTerminate_message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}
.reassignAndTerminate_actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.reassignAndTerminate_buttonsTwo > button {
  margin-right: 10px;
  width: 125px;
}

.ReassignTerminateTasksPanel {
  max-height: 70%;
  overflow: auto;
}
