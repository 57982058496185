.productContainer{
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}


.addNewProductContainer {
    width: 100%;
    height: 180px;
    padding-top: 5px;
    box-sizing: border-box;
}

.filterProductContainer{
    width: 100%;
    height: 42px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid black;
}

.productTableContainer{
    width: 100%;
    height: calc(100% - 42px);
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
}

.adminAddProductCardHeader{
    width: 100%;
    height: 2.8rem;
    background: #ffeee5;
    font-size: 0.95rem;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dangerColor{
    color: red;
}

.addNewProductContainer > div{
    background-color: white;
    border-radius: 10px;
}

.adminAddProductCardFormLineOne, .adminAddProductCardFormLineTwo{
    width: 100%;
    height: 60px;

    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.adminAddProductCardFormInput{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    box-sizing: content-box;
    width: calc((100% - 70px) / 3);
    height: 55px;
}

.adminAddProductCardFormInputLabel{
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 700;
}

.modalAdminFooter{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 20px;
}

.modalAdminFooterMandatory{
    display: flex;
    flex-direction: column;
    color: red;
    font-size: 14px;
}

.filterContentContentSectionForProduct{
    font-size: 14px;
    font-weight: 500;
    margin: 5px;
    OVERFLOW: auto;
    max-height: 100px;
}

.filterContentContentSectionForProduct > div {
    margin: 2px;
}