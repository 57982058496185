.viewAdminCardContainer {
  width: 100%;
  padding-top: 5px;
  box-sizing: border-box;
}

.viewAdminCardContainer > div {
  background-color: white;
  border-radius: 10px;
}

.adminViewDashboardContainer {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* text-align: center; */
  overflow: auto;
}

.adminDashboardTwoCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.adminViewDashboardCardStatusForm {
  height: 130px !important;
  display: flex;

  overflow: auto;
}

.adminViewDashboardCardForm {
  width: 100%;
  height: 100px;
}

.adminViewDashboardHalfWidth {
  width: 49.5% !important;
}

.viewAdminDashboardbutton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 5px;
}

.adminViewDashboardCardForm {
  display: flex;
  flex-direction: row;
  overflow: auto;

  padding-left: 12px;
  box-sizing: border-box;
}

.verticalDivider {
  position: relative;
  top: 12px;
  height: calc(100% - 40px);
  width: 0px !important;
  border: 1.9px solid #80808029;
}

.adminViewDashboardCardFormContent {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.adminViewDashboardCardForm > div {
  padding-top: 7px;
  box-sizing: border-box;
  width: 100%;
}

.adminViewDashboardCardFormColumnFifth
  .adminViewDashboardCardFormContent
  .adminViewDashboardCardFormTitle {
  width: 80px !important;
}

.adminViewDashboardCardFormColumnFifth
  .adminViewDashboardCardFormContent
  .adminViewDashboardCardFormValue {
  width: calc(100% - 80px) !important;
}

.adminViewDashboardCardFormColumnFourth
  .adminViewDashboardCardFormContent
  .adminViewDashboardCardFormTitle {
  width: 70px !important;
}

.adminViewDashboardCardFormColumnFourth
  .adminViewDashboardCardFormContent
  .adminViewDashboardCardFormValue {
  width: calc(100% - 70px) !important;
}

.adminViewDashboardCardFormColumnThird
  .adminViewDashboardCardFormContent
  .adminViewDashboardCardFormTitle {
  width: 80px !important;
}

.adminViewDashboardCardFormColumnThird
  .adminViewDashboardCardFormContent
  .adminViewDashboardCardFormValue {
  width: calc(100% - 80px) !important;
}

.adminViewDashboardCardFormColumnSecond
  .adminViewDashboardCardFormContent
  .adminViewDashboardCardFormTitle {
  width: 90px !important;
}

.adminViewDashboardCardFormColumnSecond
  .adminViewDashboardCardFormContent
  .adminViewDashboardCardFormValue {
  width: calc(100% - 90px) !important;
}

.adminViewDashboardCardFormTitle {
  width: 113px;
  display: flex;
  font-size: 0.85rem;
  font-weight: bold;
}

.adminViewDashboardCardFormValue {
  font-size: 0.81rem;
  width: calc(100% - 113px);
  display: flex;
}

.adminViewDashboardCardFormProductCategory {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mandateory {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: bold;
}

.requestStatusOuterSuccessCircle {
  border: 1px solid green;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.requestStatusOuterErrorCircle {
  border: 1px solid red;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.requestStatusOuterPendingCircle {
  border: 1px solid #b3b303;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.adminDashboardRequestStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  height: 100%;
}

.adminDashboardRequestStatus > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminDashboardRequestCreation {
  width: calc(100% / 5);
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adminDashboardRequestProgress {
  height: 100%;
  width: calc(100% - (100% / 5));
}

.adminDashboardRequestProgressTask {
  height: 100%;
  width: calc(100% / 4);
  display: inline-block;
}

.approvarComment {
  width: 180px;
  height: 43px;
  background-color: #80808042;
  border-radius: 3px;
  overflow: auto;
  padding-left: 4px;
  padding-bottom: 1px;
}

.approvarComment::-webkit-scrollbar {
  width: 3px;
  border-radius: 2px;
  height: 3px;
}

.dashedLine {
  position: relative;
  top: -80%;
  left: calc((100% / 2) + 0px);
  border: 1px dashed;
  width: 74%;
  z-index: 999999999;
  height: 0px;
}

.dashedLineInProgress {
  position: relative;
  top: -80%;
  left: calc((100% / 2) + 27px);
  border: 1px dashed;
  width: 74%;
  z-index: 999999999;
  height: 0px;
}
