.selectCompanyCard_home {
  margin: 10px 25px;
}

.selectCompanyCard_header {
  width: 100%;
  height: 2.5rem;
  background: #f9ccb5; /*#ffeee5;*/
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectCompanyCard_section {
  width: 100%;
  height: 322px;
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selectCompanyCard_sectionCenterArticle {
  width: calc(100% - 7px);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.selectCompanyCard_company {
  --individualCompanyWidth: 40%;
  height: 350px;
  width: var(--individualCompanyWidth);
  border: 1px solid rgba(0, 0, 0, 0.315);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 20px calc(((90% - (2 * var(--individualCompanyWidth))) / 8) - 1px);
}

.selectCompanyCard_companyHeader {
  background-color: #c7c7c72c;
  border-radius: 10px 10px 0px 0px;
  padding-left: 15px;
  font-weight: bold;
}

.selectCompanyCard_companyHeader > label {
  vertical-align: middle;
  margin: auto;
  padding: 4px;
}

.selectCompanyCard_companySection {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* scrollbar-width: thin; */
  overflow-y: auto;
}

.selectCompanyCard_companySection::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  height: 10px;
}
.selectCompanyCard_companySection::-webkit-scrollbar-track {
  border-radius: 5px;
}
.selectCompanyCard_companySection::-webkit-scrollbar-thumb {
  border-radius: 5px;
  /* background-color: #848482; */
}

.selectCompanyCard_companySection > label {
  cursor: default;
  margin-left: 0px;
  margin-right: 0px;
  display: block;
  padding: 0 16px;
}
#editDelete {
  width: auto;
}

.editCompanyIcon,
.deleteCompanyIcon {
  cursor: pointer;
}

.css-i4bv87-MuiSvgIcon-root,
svg.css-vubbuv {
  width: 0.7em !important;
  height: 0.7em !important;
}

.company_searchButton {
  background-color: #ffc5a0cc !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
}

.searchIcon {
  font-size: 2rem !important;
}

.companyFilterContentHeader {
  background-color: #ffeee5;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  font-size: 17px;
  font-weight: 700;
}
@media only screen and (max-width: 930px) {
  .fitermodel-company {
    width: 80% !important;
    height: 40% !important;
    overflow: auto;
  }
}
