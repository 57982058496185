.approvar_workFlowCard {
  width: 98%;
  margin: 0 auto;
  min-height: 58px;
  margin-top: 15px;
  background-color: #ffffff59;
  border: 1px solid rgba(0, 0, 0, 0.082);
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.082);
}
.workflow_selectUserRolesAction > Button {
  font-size: 12px !important;
  padding: 0.3rem 0.6rem !important;
}
.header_container {
  width: 100%;
  height: 3rem;
  background: #ffeee5;
  border-radius: 10px 10px 0px 0px;
  padding-left: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.header_text_container {
  width: 98%;
  display: flex;
  justify-content: space-between;
}

.approvar_workFlowCard_Text_Container {
  display: inline-block;
}

.workflow_Text {
  padding-right: 20px;
  font-size: 1.05rem;
  font-weight: bold;
}

.note_text {
  font-size: 11px;
  color: gray;
}

.approvarWorkflow_mainItemSection {
  padding-top: 2px !important;
  font-size: 0.8rem !important;
  /* height: 250px !important; */
}

.approvarWorkflow_Parent {
  margin: 10px 10px 7px 10px;
  /* height: 240px !important; */
  /* height: 140px; */
}

.approvarWorkflow_Header {
  width: 100%;
  height: 3rem;
  background: #ffeee5;
  border-radius: 10px;
  padding-left: 33px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-bottom: unset;
}

.approvarWorkflowTypeContainer {
  padding-left: 32px;
}
.approvarWorkflowType {
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  background: white;
  border-radius: 0.5rem;
  font-style: italic;
  font-weight: lighter;
}
