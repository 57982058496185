.confirmCheckBoxContainer {
    width: 95%;
    display: flex;
    justify-content: right;
}

.approvar_trainingsCheckbox{
    height:40px;
     border-radius: 6px;
     background-color:#ffeee5;
     /* width:100%; */
     margin-bottom: -8px;
     /* margin-left: 100px; */
     margin-top: 2px;
     padding-top:0px
}

.selectedSponserSupervisor {
    margin: 10px 10px 7px 10px;
    height: 100%;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.edit_header {
    width: 100%;
    /* height: 40px; */
    height: 3rem;
    background: #ffeee5;
    border-radius: 10px;
    padding-left: 33px;
    padding-top: 10px;
    box-sizing: border-box;
}

.request_commentsParent {
    margin: 10px 10px 7px 10px;
    background-color: #E0F1F7;
    padding-bottom: 2px;
    border-radius: 10px;
    height: 232px;
}

.request_commentHeader {
    width: 100%;
    height: 58px;
    background: #E0F1F7;
    border-radius: 10px;
    padding-left: 33px;
    padding-top: 12px;
    box-sizing: border-box;
}


.approver_commentsParent {
    margin: 10px 10px 7px 10px;
    background-color: #ffeee5;
    padding-bottom: 2px;
    border-radius: 10px;
    height: 232px;
}

.approver_commentHeader {
    width: 100%;
    height: 48px;
    background: #ffeee5;
    border-radius: 10px;
    padding-left: 19px;
    padding-top: 12px;
    box-sizing: border-box;
}

.workflow_training_container {
    width: 100% ;
    display: flex;
}

.workflow_container {
    width: 60%;
    padding-left: 12px;
}

.training_container {
    width: 40%;
}

.editIcon {
    text-align: right;
    background-color: white;
    border-radius: 2px;
    font-size: 24px !important;
    cursor: pointer !important;
    position: relative;
}
