.productTableContainer > div{
    border-radius: 10px 0px 10px 10px !important;
}

.editAffiliate{
    height: 170px;
    padding: 10px;
}

.affiliateOverflow{
    overflow: auto;
    height: 100px;
}

.productTableContainer table td svg.clickable {
    fill: rgb(128, 128, 128);
    width: 0.7em;
    height: 0.7em;
}
@media only screen and (max-width: 930px) {
    .fitermodel-affiliate {
        width: 60% !important;
        height: 53% !important;
    }
}