.accountSumary_parentSection {
  margin-top: 0px !important;
  padding-bottom: 8px;
}
.accountSummary_mainItemSection {
  padding-top: 2px !important;
  font-size: 0.8rem !important;
  height: 162px;
}
.accountSummary_tableHeading {
  vertical-align: top;
}
/* Trainings Styling */
.accountSummary_parent {
  margin: 10px 10px 7px 10px;
  height: 232px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.accountSummary_header {
  width: 100%;
  /* height: 40px; */
  height: 3rem;
  background: #e0f1f7;
  border-radius: 10px;
  padding-left: 33px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-bottom: unset;
}
.accountSummary_mainHeading {
  /* font-size: 18px;
    font-weight: bold !important; */
  font-size: 1.05rem !important;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  padding-left: 0px !important;
}
.accountSummary_red {
  color: #ff0000;
  font: italic normal normal 0.8rem sans-serif !important;
}
.accountSummary_headingNoteText {
  /* font-weight: 100;
    font-size: 14px; */
  text-align: left;
  font: italic normal normal 0.8rem sans-serif !important;
  letter-spacing: 0px;
  color: #797979;
  padding: 6px 6px 6px 12px !important;
}
.accountSummary_trainingsHeadingNoteText {
  /* font-weight: 100;
    font-size: 14px; */
  text-align: left;
  font: italic normal normal 0.8rem sans-serif !important;
  letter-spacing: 0px;
  color: #797979;
  padding: 19px 6px 6px 0px !important;
  left: 5%;
  position: relative;
}
.accountSummary_note {
  font: italic normal normal 0.8rem sans-serif !important;
  letter-spacing: 0px;
  color: #000000;
}
.accountSummary_trainingsBottomNote {
  display: block;
  height: 18px;
  bottom: 4px;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  width: 97%;
  font: italic normal normal 0.6rem sans-serif !important;
  letter-spacing: 0px;
  color: #000000;
}
.accountSummary_trainingsTable {
  /* padding-left: 13px !important;
    padding-bottom: 10px !important; */
  overflow-y: auto;
  height: 107px;
  display: block;
  padding: 10px 0px 10px 13px !important;
  margin-bottom: 5px;
}
/* width */
/* table::-webkit-scrollbar {
    width: 3px;
} */

/* Track */
/* table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 2px;
} */

/* Handle */
/* table::-webkit-scrollbar-thumb {
    background: darkgrey; 
    border-radius: 2px;
} */

/* Handle on hover */
/* table::-webkit-scrollbar-thumb:hover {
    background: #555; 
} */
.accountSummary_column_headers {
  font: normal normal bold 20px/55px Adobe Clean;
  padding: 6px !important;
}
.accountSummary_section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* Comment Styling */
.accountSummary_commentsParent {
  margin: 10px 10px 7px 10px;
  background-color: #e0f1f7;
  padding-bottom: 2px;
  border-radius: 10px;
  height: 232px;
}
.accountSummary_commentHeader {
  width: 100%;
  height: 48px;
  background: #e0f1f7;
  border-radius: 10px;
  padding-left: 19px;
  padding-top: 12px;
  box-sizing: border-box;
}
.acountSummary_commentBox {
  width: 90%;
  padding: 12px;
  border-radius: 10px 10px 0 10px;
  resize: none !important;
}

/* Role & Products */
.accountSummary_rolesProductsParent {
  margin: 10px 10px 7px 10px;
  height: 158px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.accountSummary_rolesProductsTable {
  padding-left: 12px !important;
  padding-right: 12px !important;
  /* padding-bottom: 10px !important; */
  overflow: auto;
  height: 92px;
  display: block;
}
.accountSummary_productsTable {
  padding-left: 0px !important;
  margin-bottom: 5px !important;
  overflow-y: auto;

  display: block;
  width: 45%;
  top: 17%;
  position: relative;
}
.bar {
  font-size: 58px;
  font-weight: 100;
  color: lightgrey;
  position: relative;
  top: 5px;
  height: 100px;
}
.accountSummary_editIcon {
  text-align: right;
  background-color: white;
  border-radius: 6px;
  font-size: 24px !important;
  cursor: pointer;
}
.accountSummary_rolesRow {
  padding-left: 12px;
  padding-right: 12px;
}

/* Sponser Supervisor Section*/
.accountSummary_sponserSupervisorParent {
  margin: 10px 10px 7px 10px;
  height: 158px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

/* Affiliates Section */
.accountSummary_affiliatesParent {
  margin: 10px 10px 7px 10px;
  border-radius: 10px !important;
}
.accountSummary_headingSubNoteText {
  line-height: 1.37;
}
.accountSummary_affiliateBar {
  font-size: 24px;
  font-weight: 100;
  color: lightgrey;
  position: relative;
  top: 2px;
}
.accountSummary_affiliateIconStyle {
  text-align: right;
  padding-right: 18px !important;
  padding-top: 20px !important;
}
/* User Details Section */
.accountSummary_userDetailsParent {
  margin: 10px 10px 7px 10px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.userDetailsContainer {
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
}
.accountSummary_userDetailsTable {
  padding-left: 0px !important;
  padding-bottom: 10px !important;
  overflow-y: auto;
  height: 118px;
  display: block;
}
.accountSummary_userDetailsTable tr:first-child {
  display: block;
  margin-top: 10%;
}
.accountSummary_userDetailsTable tr {
  display: block;
  padding: 0px 14px 0px 14px;
}
.accountSummary_userDetailsTable td {
  padding-left: 34px;
}
.accountSummary_sponserSection {
  padding: 1rem;
}
.accountSummary_sponserHeading {
  font-size: 0.9rem;
  font-weight: 700;
}
.accountSummary_sponserText {
  font-size: 0.9;
  font-weight: 400;
}

.firstTableRow {
  margin-top: 8% !important;
  padding-left: 2px !important;
}
.fourthTableRowMargin {
  margin-top: 8% !important;
}
.fourthTableRow {
  padding: 0px 2px 0px 2px !important;
}
.fifthTableRow {
  margin-top: 15% !important;
  padding: 0px 14px 0px 8px;
}
.userDetailsTable1 {
  width: 22%;
}
.userDetailsTable2 {
  width: 19%;
}
.userDetailsTable3 {
  width: 19%;
}
.userDetailsTable4 {
  width: 24%;
}
.userDetailsTable5 {
  width: 13%;
}
.userDetailsSection {
  padding: 12px 14px;
}
.accountSummary_mainItemSection {
  height: auto;
}
.userDetails_body {
  width: 100% !important;
}
.accountSummary_rolesRowsSize {
  height: 1px;
}
.accountSummary_rolesRows {
  top: 8px;
  position: relative;
  display: contents;
}
.accountSummary_crossModalIcon {
  top: 11px;
  left: 95%;
  position: absolute;
  font-size: 35px !important;
  border-radius: 10px;
  cursor: pointer;
  color: grey;
  background-color: white;
  z-index: 99999;
}
.summaryModalParent .userDetailsCard {
  width: 100% !important;
  margin: auto !important;
}
.summaryModalParent .userDetailsCardMain {
  width: 100% !important;
  margin: auto !important;
}
.summaryModalParent .userDetails_grid_item {
  padding: 10px !important;
}
.summaryModalParent .userDetails_grid {
  padding: 0 10px !important;
}
.summaryModalParent .inputTextField {
  width: 100% !important;
  height: 1em !important;
}
.summaryModalParent .dropdownSelectInput {
  width: 100% !important;
}
.summaryModalParent input {
  font-size: 12px !important;
  height: 0.74rem !important;
}
.summaryModalParent .dropdownSelectInput {
  font-size: 12px !important;
  height: 2.46em !important;
}
.summaryModalParent .userDetailsCard_header {
  padding-left: 1rem !important;
}
.summaryModalMenuList {
  font-size: 12px !important;
}
ul:has(> li.summaryModalMenuList) {
  max-height: 7rem !important;
}
.summaryModalButtons {
  right: 16px;
  position: relative;
  padding-top: 10px;
  display: flex;
  justify-content: right;
}

.summaryModalButtons > button {
  margin: 0 5px;
}
.summaryModalParent .selectProductCard_section {
  height: 100% !important;
}
.summaryModalParent .selectProductCard_sectionCenterArticle {
  height: 297px !important;
}
.selectProductCard_productSection::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  height: 10px;
}
.selectProductCard_productSection::-webkit-scrollbar-track {
  border-radius: 5px;
}
.selectProductCard_productSection::-webkit-scrollbar-thumb {
  border-radius: 5px;
  /* background-color: #888; */
}
.summaryModalParent .selectProductCard_product {
  height: unset !important;
  width: 220px;
  margin: 15px calc(((100% - (4 * 260px)) / 8) - 1px);
  margin-right: 32px;
  margin-left: 0px;
}
.summaryModalParent .productCheckboxClass > svg {
  font-size: 17px !important;
}
.summaryModalParent .productCheckboxLabelClass {
  font-size: 12px !important;
}
.summaryModalParent .productCheckboxHeaderClass > svg {
  font-size: 18px !important;
}
.summaryModalParent .productCheckboxHeaderLabelClass {
  font-size: 13px !important;
}
.summaryModalParent .workFlowCard {
  margin-top: 0px !important;
  width: 100% !important;
  height: 330px !important;
  overflow: auto !important;
}
.summaryModalParent .workflow_selectUserRoles {
  padding: 0.4rem !important;
  height: 262px !important;
}
.summaryModalParent .workFlow_body {
  height: 196px !important;
  top: 10px;
  position: relative;
}
.summaryModalParent .workFlowCard_header {
  padding-left: 1.2rem !important;
  position: fixed;
  z-index: 9999;
}
.summaryModalParent .workFlow_element {
  padding: 1px !important;
}
.summaryModalParent .workflow_selectUserRolesHead {
  font-size: 14px !important;
  padding: 1rem 1rem 1rem 1rem !important;
  top: 32px;
  position: relative;
}
.summaryModalParent p.rolesCheckboxLabelClass {
  font-size: 12px !important;
  line-height: 12px !important;
}
.summaryModalParent .rolesCheckboxClass > svg {
  font-size: 22px !important;
}
.summaryModalParent .workflow_userRolesSelected {
  margin-top: 28px !important;
}
.summaryModalParent .rolesChipClass {
  height: 28px !important;
}
.summaryModalParent .rolesChipClass > svg {
  font-size: 1.4rem !important;
}
.summaryModalParent .workflow_selectUserRolesAction > Button {
  font-size: 12px !important;
  padding: 0.3rem 0.6rem !important;
}
.summaryModalParent label:has(> .rolesCheckboxClass) {
  max-height: 58px !important;
}
.summaryModalParent .workflow_selectUserRolesAction > Button {
  top: 30px !important;
}

/* Sponser Modal Styles */
.sponserSupervisor_ModalCard {
  min-width: 600px;
  min-height: 200px;
}
.sponserSupervisor_modalCardHeader {
  width: 100%;
  height: 2.5rem;
  background: #ffeee5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.sponserSupervisorSearch_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem 0;
}
.sponserSupervisorSearch_searchText {
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 1rem;
}
.sponserSupervisorSearch_textFieldWithButton {
  display: flex;
}
.sponserSupervisorSearch_textField {
  width: 24rem;
  font-style: italic;
  color: #9a9898;
  text-transform: capitalize;
  background: #fff;
}
.sponserSupervisorSearch_searchButton {
  background-color: #ffc5a0cc !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: none !important;
  min-width: 2rem !important;
  cursor: pointer;
}
.sponserSupervisor_searchIcon {
  font-size: 1.5rem !important;
}
.sponserSupervisorSearch_userList {
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.sponserSupervisorSearch_userList::-webkit-scrollbar {
  width: 12px;
}

.sponserSupervisorSearch_userList::-webkit-scrollbar-thumb {
  border-radius: 8px;
  height: 84px;
  background: #ffc5a0cc;
}

.sponserSupervisorSearch_userList::-webkit-scrollbar-track {
  border-radius: 8px;
  background: #eee;
}

.loader-container,
.error-container {
  width: 27.5rem !important;
  text-align: center;
  padding: 10px 0;
}
.loader-container svg {
  color: #ffcfb2;
}

.error-text {
  color: #ff0000da;
}

.accountSummary_sponsorInstruction {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  font: italic normal normal 0.8rem Adobe Clean !important;
  letter-spacing: 0px;
  color: #797979;
  padding: 6px 6px 6px 12px !important;
}

.accountSummary_sponsorWithEditIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .MuiGrid-grid-md-11 {
    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
    flex-basis: 91.666667%;
    max-width: 91.666667%;
  }
  .userDetailsSection .MuiGrid-item:has(div):nth-child(odd) {
    -webkit-flex-basis: 20%;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
  .userDetailsSection .MuiGrid-item:has(div):nth-child(even) {
    -webkit-flex-basis: 29%;
    -ms-flex-preferred-size: 29%;
    flex-basis: 29%;
    max-width: 29%;
  }
  .accountSummary_userDetailsParent,
  .accountSummary_mainItemSection {
    height: auto;
  }
  .userDetailsSection {
    padding-bottom: 10px;
  }
}
