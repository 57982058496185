.trainingsContainer {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  padding: 10px 20px;
}
.trainingsTableSearchContainer {
  display: flex;
  justify-content: space-between;
}

.trainingsTableContainer {
  width: 100%;
  height: calc(100% - 200px);
  /* border: 1px solid green; */
  padding-top: 8px;
  box-sizing: border-box;
}

.trainingsModalParent .rolesCheckboxClass > svg {
  font-size: 22px !important;
}

.trainingsModalParent label:has(> .rolesCheckboxClass) {
  height: 20px !important;
}

.trainingsModalParent p.rolesCheckboxLabelClass {
  font-size: 12px !important;
}

.trainings_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  min-height: 300px;
  border-radius: 10px;
  background-color: white;
}

.trainings_container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem;
}

.trainingsSearchUser_textFieldWithButton,
.trainingsRASearchApprover_textFieldWithButton {
  display: flex;
}
.trainingsSearchUser_textField,
.trainingsRASearchApprover_textField {
  width: 25rem;
  font-style: italic;
  color: #9a9898;
  text-transform: capitalize;
  background: #fff;
}

.trainingsRASearchApprover_textField {
  width: 28rem;
}

.trainingsSearchUser_crossIcon,
.trainingsRASearchApprover_crossIcon {
  font-size: 2rem !important;
}

.trainingsSearchUser_searchButton,
.trainingsRASearchApprover_searchButton {
  background-color: #ffc5a0cc !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
}

.trainingsSearchUser_userList,
.trainingsRASearchApprover_userList {
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.trainings_searchFilterDownload {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 1rem;
}

.btn {
  background-color: #ffc5a0cc !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
  padding: 0.4rem !important;
}

.btn--filter {
  margin-right: 0.5rem !important;
}

.btn--download {
  text-transform: lowercase !important;
}

.filterIcon,
.donwloadIcon,
.searchIcon {
  font-size: 2rem !important;
}

.trainings_tableContainer {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
}

.trainings_modalCard {
  width: 100%;
  height: 100%;
}

.trainings_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  min-height: 300px;
  border-radius: 10px;
  background-color: white;
}

.trainings_modalCardHeader,
.trainingsRA_modalCardHeader {
  width: 100%;
  height: 2.5rem;
  background: #ffeee5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.trainings_Type {
  padding: 1rem 1rem 0 1rem;
}

.trainings_TypeHeading {
  font-size: 1rem;
  font-weight: 700;
}

.trainings_TypeChip {
  margin: 0.5rem 0.5rem 0.5rem 0 !important;
}

.trainings_TypeChipSelected {
  background-color: #ffc5a0cc !important;
}

.trainings_TypeChip:hover {
  cursor: pointer;
}

.trainings_modalCardFooter {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

.trainings_terminateModalContainer {
  min-width: 600px;
  min-height: 300px;
}

.trainings_terminateModalContainer > div {
  margin-bottom: 10px;
}

.trainings_terminate_topBorder {
  border-radius: 10px 10px 0px 0px;
  background-color: #d52b1e;
  height: 12px;
}
.terminate_icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.trainings_iconFail {
  border-radius: 50%;
  border: 1px dotted #d52b1e;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.trainings_terminateInstrWithTextBox {
  text-align: center;
}

.trainings_terminateInstruction,
.trainings_terminateLabel {
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
}
.trainings_terminateInstruction {
  margin-bottom: 0.5rem;
}

.trainings_terminateLabel {
  margin-bottom: 0.2rem;
}

.trainings_terminatelabelWithTextBox {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 70%;
  margin: 0 auto;
}

.terminate_textBox {
  border-radius: 8px;
  resize: none !important;
}

.trainings_terminateActionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
}

.trainingsRA_ModalCard {
  min-width: 600px;
  min-height: 300px;
}

.trainingsRASearchApprover_wrapper {
  text-align: center;
  padding: 5rem;
}

.trainingsRASearchApprover_searchText {
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 1rem;
}

.trainings_tableBody {
  background-color: #fff;
  font-size: 0.9rem;
}

.trainings_tableRow {
  width: 100%;
}

.trainings_tableRow:nth-child(odd) {
  background-color: #3b97950c;
}

.trainings_tableRow:nth-child(even) {
  background-color: #ffffff98;
}

.trainings_tableRow > td {
  box-sizing: border-box;
  padding: 0.5rem;
  border-right: 1px solid #80808040;
}

.trainingsTableContainer .clickable {
  fill: rgb(128, 128, 128) !important;
    width: 0.7em !important;
    height: 0.7em !important;
}