.roles_container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem;
}

.roles_addRolesCardHeader,
.roles_existingRolesCardHeader {
  width: 100%;
  height: 3rem;
  background: #ffeee5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.roles_addBtn {
  background-color: #ffc5a0cc !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
}

.roles_searchFilterDownload {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin: 1rem 0;
}

.rolesSearchUser_textField {
  width: 25rem;
  font-style: italic;
  color: #9a9898;
  text-transform: capitalize;
  background: #fff;
}

.rolesSearchUser_textFieldWithButton {
  display: flex;
}

.roleSearchUser_crossIcon {
  font-size: 2rem !important;
}

.roleSearchUser_searchButton {
  background-color: #ffc5a0cc !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
}

.btn {
  background-color: #ffc5a0cc !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
  padding: 0.4rem !important;
}

.btn--filter {
  margin-right: 0.5rem !important;
}

.filterIcon,
.donwloadIcon,
.searchIcon {
  font-size: 2rem !important;
}

.roles_tableContainer {
  box-sizing: border-box;
  margin: 1rem 0;
  width: 100%;
  height: calc(100% - 180px);
  overflow: auto;
}

.roles_tableContainer::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
  height: 12px;
}
.roles_tableContainer::-webkit-scrollbar-track {
  border-radius: 5px;
}
.roles_tableContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  /* background-color: #848482; */
}

/*Modals*/
.roles_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  min-height: 200px !important;
  border-radius: 10px;
  background-color: white;
}

.roles_modalCard {
  width: 100%;
  height: 100%;
}

.roles_modalCardHeader {
  width: 100%;
  height: 2.5rem;
  background: #ffeee5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.roles_Type {
  padding: 1rem 1rem 0 1rem;
}

.roles_TypeHeading {
  font-size: 1rem;
  font-weight: 700;
}

.roles_TypeChip {
  margin: 0.5rem 0.5rem 0.5rem 0 !important;
}

.roles_TypeChipSelected {
  background-color: #ffc5a0cc !important;
}

.roles_TypeChip:hover {
  cursor: pointer;
}

.roles_modalCardFooter {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

.addUpdateRoleContainer {
  min-width: 1000px;
  min-height: 500px;
}

/*Tabs*/
.role_addOrModifyRoleContainer,
.role_addOrModifyGroups,
.role_addOrModifyTrainings,
.role_addOrModifyDAC {
  /* background: red; */
  padding: 2rem;
}

/* .role_grpsTransDacsCommon{
    height: 260px;
} */

.role_grpTrainingDacContent {
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 30px 0 !important;
}

.role_grpsTransDacsActionBtnsCommon {
  margin-top: 2rem !important;
}

.role_addOrModifyRole {
  width: 100%;
}
.role_textArea {
  /* background: red; */
  border-radius: 0.5rem;
  width: 25rem;
  font-size: 1rem;
  font-family: monospace;
  padding: 0.5rem;
  margin-top: 0.5rem;
  resize: none !important;
}
.role_actionButons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.btnRole {
  border: 2px solid;
  padding: 0.5rem 1rem !important;
}

.btnRoleCancel {
  margin-right: 1rem !important;
}

.role_addOrModifyCheckbox {
  padding: 0rem !important;
}

.role_formLabel {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}
