.adminDashboardTableBox {
  width: 100%;
  height: calc(100% - 162px);
  /* border: 1px solid green; */
  padding-top: 5px;
  box-sizing: border-box;
}

.filterSortIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  width: 13px;
}

.adminTableLoader {
  padding: 5px;
}

.dashBoardReqCounts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dashBoardPagination {
  display: flex;
  justify-content: center;
  width: 80%;
  align-items: center;
}

.requestCountNumers {
  font-size: 15px;
  font-weight: 650;
  text-indent: 5px;
}

.requestCountText {
  font-size: 15px;
  text-indent: 5px;
}
