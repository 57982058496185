.selectProductCard_header {
  width: 100%;
  height: 2.5rem;
  background: #ffeee5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectProductCard_section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.selectProductCard_sectionSideArticle {
  /* width: 40px; */
  /* min-height: 322px; */
  display: flex;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  min-width: 3rem;
}

.selectProductCard_sectionCenterArticle {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.selectProductCard_sectionCenterArticleSelectionNotAllowed {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: red;
}

.selectProductCard_sectionCenterArticleSelectionNotAllowed > span {
  text-align: center;
}

.selectProductCard_product {
  --individualProductWidth: 23%;
  height: 280px;
  width: var(--individualProductWidth);
  border: 1px solid rgba(0, 0, 0, 0.315);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 20px calc(((100% - (4 * var(--individualProductWidth))) / 8) - 1px);
}

.selectProductCard_productHeader {
  background-color: #c7c7c72c;
  border-radius: 10px 10px 0px 0px;
  padding-left: 15px;
  font-weight: bold;
}

.selectProductCard_productSection {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  box-sizing: border-box;
  /* scrollbar-width: thin; */
  overflow-y: auto;
}

.css-i4bv87-MuiSvgIcon-root,
svg.css-vubbuv {
  width: 0.7em !important;
  height: 0.7em !important;
}
