.adminDashboardTableContainer {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.adminDashboardTableRecord {
  width: 100%;
  height: 120px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.adminDashboardTableRecord > div {
  width: 233px;
  height: calc(100% - 20px);
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;

  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.adminDashboardTableRecordOne {
  border: 2px solid #00acff !important;
}
.adminDashboardTableRecordTwo {
  border: 2px solid #ffdd00 !important;
}
.adminDashboardTableRecordThree {
  border: 2px solid #5400ff !important;
}
.adminDashboardTableRecordFour {
  border: 2px solid #ff003d !important;
}

.recordDataNumber {
  height: 100%;
  width: 100px;
  font-size: 45px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.recordDataDivider {
  border: 1px solid black;
  height: calc(100% - 21px);
  margin: 0px 5px;
}

.recordRequestType {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 20px;
  font-size: 17px;
  font-weight: 750;
}

.recordData {
  height: calc(100%);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recordDataInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 9px;
  justify-content: center;
  height: 100%;
  width: calc(100% - 110px);
}

.recordDataInfoLineOne,
.recordDataInfoLineTwo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 1250px) {
  .fltermodel-dash {
    /* height: 90% !important; */
    overflow: auto;
  }
}

@media only screen and (max-width: 1130px) {
  .adminDashboardTableRecord > div {
    margin-right: 10px;
    height: 100%;
  }
  .adminDashboardTableRecordFour {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1050px) {
  .adminDashboardTableRecord {
    height: 140px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 930px) {
  .recordRequestType {
    font-size: 13px;
  }
  .recordDataNumber {
    width: auto;
    font-size: 33px;
  }
  .fltermodel-dash {
    width: 90% !important;
    /* height: 87% !important; */
    overflow: auto;
  }
}
