.userDetails_selectAffiliate_container {
  max-width: 85rem;
  margin: 0 auto;
}
/*User Details*/
.userDetailsCardMain {
  width: 95%;
  margin: 0 auto;
  background-color: #ffffff59;
  border: 1px solid rgba(0, 0, 0, 0.082);
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.082);
}
.userDetailsCard_header {
  width: 100%;
  height: 2.5rem;
  background: #ffeee5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userDetails_deactivateAccountInstruction {
  font: italic normal normal 0.8rem Adobe Clean !important;
  letter-spacing: 0px;
  color: #000000;
}

.userDetails_deactivateButtonContainer {
  text-align: right;
  padding: 1rem;
}

.userDetailsCard_body {
  width: 100%;
}

.userDetailsCard_body .MuiGrid-item {
  padding: 4px 20px !important;
}

.userDetails_grid {
  width: 100% !important;
  margin: 0 !important;
  padding: 0.5rem 0 1rem 0;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.userDetails_grid_item {
}

.inputTextField {
  padding: 0.5rem 0 !important;
  width: 100%;
}

.dropdownSelectInput {
  width: 100%;
}

.displayLabel {
  font-size: 1rem;
  font-weight: bold;
}

.selectAffiliateCard {
  width: 95%;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #ffffff59;
  border: 1px solid rgba(0, 0, 0, 0.082);
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.082);
}

.selectAffiliateCard_header {
  width: 100%;
  height: 2.5rem;
  background: #ffeee5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.affiliate_textInput {
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
}

.selectAffiliate_dropdown {
  width: 10rem !important;
}
.custom-dropdown-class {
  height: 250px;
}

.tooltip {
  position: relative;
}

.tooltip .tooltipContent {
  visibility: hidden;
  width: 50rem;
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  right: 0.8rem;
  bottom: 0.5rem;
  z-index: 1;
  transition: all 0.1s;
  box-shadow: 0px 3px 10px #0000006e;
}

.tooltip:hover .tooltipContent {
  /* overflow: scroll; */
  visibility: visible;
}

.afffiliate_quickNoteGuidelines {
  padding: 0 1rem;
}

.affiliate_common_flexClasses {
  display: flex;
  justify-content: space-between;
}

.affiliate_globalUS {
  display: table;
  width: 100%;
  font-size: 1rem;
  font-weight: lighter;
}

.affiliate_globalUS > * {
  display: table-row;
}

.affiliate_globalUS .affiliate_globalCol {
  display: table-cell;
  padding-right: 2rem;
  font-size: 0.9rem;
}

.affiliate_globalCol_head {
  color: #01a0e0;
  font-weight: bold;
  font-size: 0.9rem;
}

.affiliate_guideLinesText {
  font-size: 0.8rem;
  font-style: italic;
  font-weight: lighter;
  letter-spacing: 0;
  color: #c0c0c0;
  margin-bottom: 1.5rem;
}

.affiliate_stars {
  color: #df271b;
}

.affiliate_selectCountry {
  padding: 0rem 0rem 1.5rem;
  width: 92.5%;
  margin: 0 auto;
}

.country_display {
  max-height: 70px;
  overflow-y: auto;
  width: 100%;
}

.country_display .country_displayIndividual {
  width: 25%;
}

/*User Result*/
.userDetailsResult {
  width: 95%;
  margin: 1rem auto;
}

.accordion_summary {
  background: #dff1f7;
}

.userDetailsResult_section {
  width: 97% !important;
  margin: 0 auto !important;
}

.userDetailsResult_element {
  margin: 0.5rem;
}
.userDetailsResult_elementHeading {
  font-size: 0.8rem;
  font-weight: bolder;
}

.userDetailsResult_elementContent {
  font-size: 0.95rem;
  font-weight: normal;
}

.userDetailsResult_header {
}

.userDetailsResult_header_heading {
  font-size: 1.05rem;
  font-weight: bold;
  padding: 0.5rem;
}

.userDetailsResult_header_element {
  font-size: 0.9rem;
  font-weight: lighter;
  color: #9a9898;
  margin-left: 2rem;
}

.userDetailsResult_selectedAffiliateElement {
  margin-left: 1rem;
}

/********DEACTIVATE MODAL NOTICE***********/
.deactivate_modalContainer > div {
  margin-bottom: 10px;
}

.deactivate_topBorderError {
  border-radius: 10px 10px 0px 0px;
  background-color: #d52b1e;
  height: 12px;
}

.deactivate_topBorderSuccess {
  border-radius: 10px 10px 0px 0px;
  background-color: #00ae43;
  height: 12px;
}

.deactivate_icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.deactivate_iconSuccess {
  border-radius: 50%;
  border: 2px dotted #00ae43;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.deactivate_iconFail {
  border-radius: 50%;
  border: 1px dotted #d52b1e;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.deactivate_iconError {
  border-radius: 50%;
  border: 1px dotted #d52b1e;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.deactivate_message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.deactivate_actionButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.deactivate_deactivateText {
  color: #d52b1e;
}

.deactivate_actionButtonError > button {
  margin-right: 10px;
  width: 125px;
}

.deactivate_actionButton > button {
  width: 125px;
}

.userDetails_note {
  font: italic normal normal 0.8rem Adobe Clean !important;
  letter-spacing: 0px;
  color: #000000;
}

.userDetails_red {
  color: #ff0000;
  font: italic normal normal 0.8rem Adobe Clean !important;
}

@media only screen and (min-width: 768px) {
  .userDetailsCard_body .MuiGrid-item {
    flex-basis: 33.333%;
  }
}
