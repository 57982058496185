.reassignAndTerminate_tableBody{
    background-color: #fff;
    font-size: 12px;
}

.reassignAndTerminate_tableRow{
    width:100%;
}

.reassignAndTerminate_tableRow:nth-child(odd){
    background-color: #3b97950c;
}

.reassignAndTerminate_tableRow:nth-child(even){
    background-color: #ffffff98;
}

.reassignAndTerminate_tableCell{
    box-sizing: border-box;
    padding: 0.4rem;
    border-right: 1px solid #80808040;
}

.reassignAndTerminate_tableCellNoRecords{
    padding: 1rem;
    text-align: center;
}

.reassignAndValidate_actionIconGroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor:pointer;
}

.reassignAndValidate_actionIconGroupIcon {
    font-size: 1.8rem !important;
    font-weight: lighter;
}

.reassignAndValidate_actionIconGroup svg.clickable {
    fill: rgb(128, 128, 128);
}