.exception_header {
    width: 100%;
    height: 2.5rem;
    background: #d3fdff;
    font-size: 1.05rem;
    font-weight: bold;
    border-radius: 10px 10px 0px 0px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.exception_section {
    width: 100%;
    height: 322px;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
}

.exception_section > div{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.exception_section > div > span{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.exception_sectionTitle{
    width: 300px;
    height: 55px;
    font-size: 1.05rem;
    font-weight: bold;
}

.exception_sectionValue{
    font-size: 0.9rem;
}

.exception_sectionComment{
    height: 120px;
    background-color: rgba(128, 128, 128, 0.356);
    width: calc(100% - 300px);
    border-radius: 10px;
    justify-content: flex-start !important;
    padding-left: 10px;
    padding-top: 10px;
}